import styles from "../Home.module.css";
import { Navigate, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import i18next from "i18next";
import wap from "../../assets/wap.svg";
import AIWorks_T_C from "../../assets/AIWORKS_T&C.pdf";
import { Checkbox, DefaultButton } from "@fluentui/react";
import { saveTermsAcceptance } from "../../api/api";
import { userInfo } from "../../authConfig";

type TermsAndConditionsProps = {
    onAccept: () => void;
};

const TermsAndConditions = ({ onAccept }: TermsAndConditionsProps) => {
    const [isAgreeCheck, setIsAgreeCheck] = useState(false);
    const navigate = useNavigate();

    const checkIsUserAgree = () => {
        onAccept();
        saveTermsAcceptance();
        navigate("/assistant/chat", { replace: true });
    };

    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight
            });
        };
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        const handlePopState = (event: PopStateEvent) => {
            navigate("/assistant/chat", { replace: true });
        };

        window.addEventListener("popstate", handlePopState);

        return () => {
            window.removeEventListener("popstate", handlePopState);
        };
    }, [navigate]);

    return (
        <div>
            {userInfo.terms_accepted !== "accepted" ? (
                <div style={{ height: window.innerHeight }}>
                    <header className={styles.header} style={{ display: "flex", alignItems: "center" }}>
                        <img src={wap} alt={i18next.t("layout.wap")} aria-label={i18next.t("layout.wap")} style={{ marginLeft: "40px" }} />
                        <h2 style={{ marginLeft: "50px" }}>{i18next.t("layout.headerTitle")}</h2>
                    </header>
                    <div style={{ marginLeft: "55px", marginTop: "5px", marginBottom: "5px", alignItems: "center", fontWeight: "bold" }}>
                        {i18next.t("homepage.termsHeader")}
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            border: "1px solid black",
                            marginRight: "50px",
                            marginLeft: "50px"
                        }}
                    >
                        <embed
                            id="pdf-embed"
                            src={AIWorks_T_C}
                            type="application/pdf"
                            width={`${window.innerWidth}`}
                            height={`${window.innerHeight - 160}`}
                        ></embed>
                    </div>
                    <div style={{ paddingTop: "15px", display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "15px" }}>
                        <Checkbox label={i18next.t("homepage.terms")} checked={isAgreeCheck} onChange={() => setIsAgreeCheck(!isAgreeCheck)} />
                        <div style={{ marginLeft: "15px" }}>
                            <DefaultButton
                                disabled={!isAgreeCheck}
                                style={!isAgreeCheck ? { backgroundColor: "grey" } : { backgroundColor: "blue", color: "white" }}
                                onClick={() => checkIsUserAgree()}
                            >
                                <span style={{ color: "white", textDecoration: "none" }}>{i18next.t("homepage.agree")}</span>
                            </DefaultButton>
                        </div>
                    </div>
                </div>
            ) : (
                <Navigate to="/assistant/chat" replace />
            )}
        </div>
    );
};

export default TermsAndConditions;
