import { updateInfo, userInfo } from "../../authConfig";
import styles from "./Update.module.css";
import i18next from "../../i18n/i18n";
import wap from "../../assets/wap.svg";

const Updates = () => {
    document.title = i18next.t("updates.title");
    let updates;
    if (userInfo.locale === "en") {
        updates = JSON.parse(updateInfo.updatesEN);
    } else {
        updates = JSON.parse(updateInfo.updatesJA);
    }

    return (
        <div>
            <header className={styles.header} role={"banner"}>
                <div className={styles.headerContainer}>
                    <div className={styles.headerTitleContainer}>
                        <img src={wap} alt={i18next.t("layout.wap")} aria-label={i18next.t("layout.wap")} />
                        <h3 className={styles.headerTitle}>{i18next.t("layout.headerTitle")}</h3>
                    </div>
                </div>
            </header>

            <div
                style={{
                    overflowY: "auto",
                    height: "calc(100vh - yourHeaderHeight)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column"
                }}
            >
                <div style={{ marginTop: "50px", textAlign: "center" }}>
                    <h1>{i18next.t("updates.title")}</h1>
                </div>
                {Object.entries(updates).map(([version, details]: any) => (
                    <div
                        key={version}
                        style={{
                            background: "rgb(220, 220, 220)",
                            padding: "10px",
                            borderRadius: "5px",
                            margin: "1px",
                            width: "60%",
                            border: "1px solid white"
                        }}
                    >
                        <h2 style={{ margin: "0", marginBottom: "0px", fontWeight: "bold" }}>{version}</h2>
                        <ul style={{ listStyleType: "none", paddingInlineStart: "0" }}>
                            <ul style={{ listStyleType: "disc", paddingInlineStart: "20px" }}>
                                {Object.entries(details).map(([title, description]: any) => (
                                    <li key={title}>
                                        <strong>{title}:</strong> {description}
                                    </li>
                                ))}
                            </ul>
                        </ul>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Updates;
