import { useEffect, useState } from "react";
import styles from "../updates/Update.module.css";
import i18next from "../../i18n/i18n";
import wap from "../../assets/wap.svg";
import AIWorks_Guides from "../../assets/AIWORKS_Guidance.pdf";

const GuideLines = () => {
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight
            });
        };
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <div style={{ display: "flex" }}>
            <div>
                <header className={styles.header} role={"banner"}>
                    <div className={styles.headerContainer}>
                        <div className={styles.headerTitleContainer}>
                            <img src={wap} alt={i18next.t("layout.wap")} aria-label={i18next.t("layout.wap")} />
                            <h3 className={styles.headerTitle}>{i18next.t("layout.headerTitle")}</h3>
                        </div>
                    </div>
                </header>
            </div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "62px"
                }}
            >
                <embed id="pdf-embed" src={AIWorks_Guides} type="application/pdf" width={`${window.innerWidth}`} height={`${window.innerHeight - 63}`}></embed>
            </div>
        </div>
    );
};

export default GuideLines;
