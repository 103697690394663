import { useState, useEffect } from "react";
import { Stack, TextField } from "@fluentui/react";
import { Button, Tooltip } from "@fluentui/react-components";
import { Send28Filled, BookTemplate20Filled } from "@fluentui/react-icons";
import i18next from "../../i18n/i18n";
import styles from "./QuestionInput.module.css";
import { userInfo } from "../../authConfig";

interface Props {
    onSend: (question: string) => void;
    disabled: boolean;
    placeholder?: string;
    clearOnSend?: boolean;
    onTemplateIconClick?: () => void;
    templateContent?: any;
    onClearTemplateContent?: () => void;
    disableTextarea: boolean;
    productIndex: string | undefined;
    isAdmin: boolean;
}

export const QuestionInput = ({
    onSend,
    disabled,
    placeholder,
    clearOnSend,
    onTemplateIconClick,
    templateContent,
    onClearTemplateContent,
    disableTextarea,
    productIndex,
    isAdmin
}: Props) => {
    const [question, setQuestion] = useState<string>("");
    const [isEdited, setIsEdited] = useState<boolean>(false);

    useEffect(() => {
        if (!isEdited) {
            setQuestion(templateContent.previewPrompt ?? "");
        }
    }, [templateContent, isEdited]);

    const sendQuestion = () => {
        if (disabled || !question.trim()) {
            return;
        }

        if (!isAdmin && (productIndex === "0" || !productIndex)) {
            onSend("invalid_selection");
            return;
        }

        onSend(question);

        if (clearOnSend) {
            setQuestion("");
            setIsEdited(false);
        }
    };

    const onEnterPress = (ev: React.KeyboardEvent<Element>) => {
        if (disabled) {
            return;
        }
        if (ev.key === "Enter" && !ev.shiftKey) {
            ev.preventDefault();
            sendQuestion();
            setIsEdited(false);
            if (onClearTemplateContent) {
                onClearTemplateContent();
            }
        }
    };

    const onQuestionChange = (_ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (!newValue) {
            setQuestion("");
            setIsEdited(false);
            if (onClearTemplateContent) {
                onClearTemplateContent();
            }
        } else if (newValue.length <= 1000) {
            setQuestion(newValue);
            setIsEdited(true);
        }
    };

    const sendQuestionDisabled = disabled || !question.trim();

    const handleTemplateIcon = () => {
        setQuestion("");
        setIsEdited(false);
        if (onTemplateIconClick) {
            onTemplateIconClick();
        }
        if (onClearTemplateContent) {
            onClearTemplateContent();
        }
    };

    return (
        <Stack horizontal className={styles.questionInputContainer}>
            <TextField
                id="question-input"
                className={styles.questionInputTextArea}
                placeholder={placeholder}
                multiline
                resizable={false}
                borderless
                value={isEdited ? question : templateContent.previewPrompt ?? ""}
                onChange={onQuestionChange}
                onKeyDown={onEnterPress}
                disabled={disableTextarea}
                autoFocus
            />
            {isAdmin && (
                <div className={styles.questionInputButtonsContainer}>
                    <Tooltip content={i18next.t("template.templatePromptIcon")} relationship="label">
                        <Button
                            id="TemplateIcon"
                            size="large"
                            icon={<BookTemplate20Filled primaryFill="rgba(115, 118, 225, 1)" onClick={handleTemplateIcon} />}
                            disabled={disableTextarea}
                        />
                    </Tooltip>
                </div>
            )}
            <div className={styles.questionInputButtonsContainer}>
                <Tooltip content={i18next.t("questionInput.tooltip")} relationship="label">
                    <Button
                        id="sendButton"
                        size="large"
                        icon={<Send28Filled primaryFill="rgba(115, 118, 225, 1)" />}
                        disabled={sendQuestionDisabled}
                        onClick={sendQuestion}
                    />
                </Tooltip>
            </div>
        </Stack>
    );
};
