import React, { useEffect, useState } from "react";
import {
    Layer,
    Popup,
    FocusTrapZone,
    IconButton,
    DefaultButton,
    Icon,
    TooltipHost,
    DirectionalHint,
    PrimaryButton,
    Overlay,
    mergeStyleSets
} from "@fluentui/react";
import styles from "./DynamicTemplatePopup.module.css";
import { saveTemplate, loadTemplate, updateTemplate, deleteTemplate } from "../../api";
import "./TemplateEditor.css";
import i18next from "i18next";

interface ResponseTemplatePopupProps {
    hideDynamicTemplatePopup: boolean;
    selectedTemplate: number;
    handleTemplateIconClick: () => void;
    handleTemplateClick: (templateId: number) => void;
    handleTemplateContents: (contents: string) => void;
}

const DynamicTemplatePrompt: React.FC<ResponseTemplatePopupProps> = ({
    hideDynamicTemplatePopup,
    handleTemplateIconClick,
    handleTemplateClick,
    handleTemplateContents
}) => {
    const [isEdit, setIsEdit] = useState(false);
    const [isAdd, setIsAdd] = useState(false);
    const [isHover, setIsHover] = useState<number>(-1);
    const [editedTemplate, setEditedTemplate] = useState("");
    const [templateContents, setTemplateContents] = useState([
        { id: "", order: 0, pk: "", template: [{ title: "", prompt: "", fields: {}, previewPrompt: "" }] }
    ]);
    const [editingIndex, setEditingIndex] = useState<number | null>(null);
    const [selectedTemplate, setSelectedTemplate] = useState(Number);
    const [shouldRerender, setShouldRerender] = useState(false);
    const [userPromptValues, setUserPromptValues] = useState([{ title: "", prompt: "", fields: {}, previewPrompt: "" }]);
    const [prompt, setPrompt] = useState("");
    const [fields, setFields] = useState<any>({});
    const [previewPrompt, setPreviewPrompt] = useState("");
    const [selectedTemplateEmail, setSelectedTemplateEmail] = useState<string>("");
    const [selectedTemplateOrder, setSelectedTemplateOrder] = useState<Number | undefined>(0);
    const [deleteConfirmationDialogVisible, setDeleteConfirmationDialogVisible] = useState(false);
    const [ConfirmationDialogVisible, setConfirmationDialogVisible] = useState(false);
    const [currentId, setCurrentId] = useState<String | undefined>("");

    useEffect(() => {
        const getTemplateFromDb = async () => {
            try {
                const response = await loadTemplate();
                const data = await response.json();
                setTemplateContents(data);
            } catch (error) {
                console.error("Error fetching template data: ", error);
            }
        };
        getTemplateFromDb();
    }, [shouldRerender]);

    if (!hideDynamicTemplatePopup) {
        return null;
    }

    const handleDeleteIconClick = (id: String) => {
        setIsHover(-1);
        setCurrentId(id);
        setDeleteConfirmationDialogVisible(true);
    };

    const handleConfirmDelete = () => {
        if (currentId !== null && currentId !== undefined) {
            handleDeleteClick(currentId.toString());
        }
        setDeleteConfirmationDialogVisible(false);
    };

    const handleCancelDelete = () => {
        setDeleteConfirmationDialogVisible(false);
        setCurrentId(undefined);
    };

    const handleCancelConfirmation = () => {
        setConfirmationDialogVisible(false);
    };

    const handleEditClick = async (index: number) => {
        const response = await loadTemplate();
        const data = await response.json();
        setTemplateContents(data);
        setIsEdit(true);
        setEditingIndex(index);
        const selectedTemplateFields = templateContents[index].template[0].fields || {};
        setFields(selectedTemplateFields);
        setUserPromptValues(templateContents[index].template);
        setSelectedTemplateEmail(templateContents[index].pk);
        setSelectedTemplateOrder(templateContents[index].order);
    };

    const handleSaveClick = async () => {
        setIsHover(-1);
        if (isEdit) {
            await updateTemplate(templateContents[editingIndex!].id, userPromptValues, templateContents[editingIndex!].order).then(response => {
                return response.json();
            });
        } else {
            await saveTemplate(userPromptValues).then(response => {
                return response.json();
            });
            setTemplateContents([
                ...templateContents,
                {
                    id: templateContents.length.toString(),
                    order: 0,
                    template: userPromptValues,
                    pk: ""
                }
            ]);
            setUserPromptValues([
                {
                    title: "",
                    prompt: "",
                    fields: {},
                    previewPrompt: ""
                }
            ]);
        }
        isAdd ? setIsAdd(false) : setIsEdit(false);
        setShouldRerender(prev => !prev);
    };

    const handleDeleteClick = async (id: string) => {
        await deleteTemplate(id);
        setShouldRerender(prev => !prev);
    };

    const addTemplate = () => {
        setEditedTemplate("");
        setIsAdd(true);
        setPrompt("");
        setFields("");
        setPreviewPrompt("");
        userPromptValues[0].title = "";
        userPromptValues[0].prompt = "";
        userPromptValues[0].fields = {};
        userPromptValues[0].previewPrompt = "";
        setShouldRerender(prev => !prev);
    };

    const sendTemplateContents = (contents: any) => {
        setIsHover(-1);
        handleTemplateContents(contents);
    };

    const handleChange = (e: { target: { value: string } }) => {
        const newValue = e.target.value;
        setUserPromptValues(prevValues => {
            const updatedValues = [...prevValues];
            updatedValues[0].prompt = newValue;
            return updatedValues;
        });
        setPrompt(newValue);
        parseText(newValue);
        updateParsedText("", "", newValue);
    };

    const handleFieldChange = (e: any, key: any) => {
        const value = e.target.value;
        // const trimmedValue = value.trim();
        setFields({
            ...fields,
            [key]: value
        });
        updateParsedText(key, value, userPromptValues[0].prompt);
    };

    const parseText = (inputText: any) => {
        const regex = /\{([^}]+)\}/g;
        const matches = inputText.match(regex) || [];
        const newFields: any = { ...fields };
        matches.forEach((match: string) => {
            const key = match.replace(/[{}]/g, "");
            if (key && !newFields[key]) {
                newFields[key] = "";
            }
        });
        setFields(newFields);
    };

    const updateParsedText = (key: string, value: string, promptText: string) => {
        let updatedText = promptText || userPromptValues[0].prompt;
        Object.keys(fields).forEach(fieldKey => {
            let fieldValue = fieldKey === key ? value : fields[fieldKey];
            fieldValue = fieldValue === "" ? `{${fieldKey}}` : fieldValue;
            const regex = new RegExp(`\\{${fieldKey}\\}`, "g");
            updatedText = updatedText.replace(regex, fieldValue);
        });

        if (promptText === "") {
            updatedText = "";
        }

        let object: any = {};
        if (key) {
            object[key] = value;
        }
        setUserPromptValues(prevValues => {
            const updatedValues = [...prevValues];
            updatedValues[0] = {
                ...updatedValues[0],
                fields: { ...fields, ...object },
                previewPrompt: updatedText
            };
            return updatedValues;
        });
    };

    function RenderPreviewText() {
        const promptValues: any = userPromptValues[0].prompt;
        let result = promptValues.replace(/{([^{}]+)}/g, (match: string, p1: string) => {
            if (fields[p1]) {
                return `<span style="font-weight: bold; color: red;">${fields[p1]}</span>`;
            }
            if (fields[p1] === "") {
                return `{${p1}}`;
            }
            return match;
        });
        return <div dangerouslySetInnerHTML={{ __html: result }} />;
    }

    function RenderInputBox() {
        const inputKeys: Array<string> = [];
        const promptValues: any = userPromptValues[0].prompt;
        const regex = /{([^{}]+)}/g;
        let match: RegExpExecArray | null;
        while ((match = regex.exec(promptValues)) !== null) {
            if (match[1]) {
                inputKeys.push(match[1]);
            }
        }
        return inputKeys;
    }

    const renderedInputBoxKeys = RenderInputBox();

    const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newTitle = e.target.value;
        setUserPromptValues(prevValues => {
            const updatedValues = [...prevValues];
            updatedValues[0].title = newTitle;
            return updatedValues;
        });
    };

    const popupStyles = mergeStyleSets({
        root: {
            backgroundcolor: "rgba(0, 0, 0, 0.5)",
            color: "black",
            bottom: "0",
            left: "0",
            position: "absolute",
            right: "0",
            top: "0"
        },
        content: {
            background: "white",
            left: "50%",
            maxWidth: window.innerWidth < 1000 ? "300px" : "1000px",
            padding: "1em 3em 2em 2em",
            position: "absolute",
            top: "50%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            flexDirection: "column",
            borderRadius: "15px",
            border: "2px solid",
            borderColor: "grey"
        }
    });

    function deepEqual(obj1: any, obj2: any) {
        if (obj1 === obj2) {
            return true;
        }
        if (typeof obj1 !== "object" || obj1 === null || typeof obj2 !== "object" || obj2 === null) {
            return false;
        }

        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);

        if (keys1.length !== keys2.length) {
            return false;
        }

        for (let key of keys1) {
            if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
                return false;
            }
        }

        return true;
    }

    const checkContentsEntered = () => {
        if (isAdd && userPromptValues[0].title === "" && userPromptValues[0].prompt === "") {
            setIsAdd(false);
            setIsHover(-1);
        } else {
            if (isAdd) {
                setConfirmationDialogVisible(true);
            }
            if (isEdit) {
                if (
                    userPromptValues[0].title.trim() === templateContents[editingIndex!].template[0].title.trim() &&
                    userPromptValues[0].prompt.trim() === templateContents[editingIndex!].template[0].prompt.trim() &&
                    deepEqual(userPromptValues[0].fields, templateContents[editingIndex!].template[0].fields)
                ) {
                    setIsEdit(false);
                    setIsHover(-1);
                } else {
                    setConfirmationDialogVisible(true);
                }
            }
        }
    };

    return (
        <Layer>
            <Popup className={styles.root} role="dialog" aria-modal="true">
                <FocusTrapZone>
                    <div role="document" className={styles.content}>
                        <div>
                            <header>
                                {!isAdd && !isEdit && (
                                    <>
                                        <Icon iconName="Message" style={{ padding: "5px", fontSize: "20px" }} />
                                        <span className={styles.headerFontSize}>{i18next.t("template.templateTitle")} </span>
                                        <IconButton
                                            style={{ color: "blueviolet", float: "right" }}
                                            title={i18next.t("template.close")}
                                            iconProps={{ iconName: "Cancel", style: { fontSize: "20px" } }}
                                            onClick={() => {
                                                if (isAdd || isEdit) {
                                                    setIsAdd(false);
                                                    setIsEdit(false);
                                                    setIsHover(-1);
                                                } else {
                                                    handleTemplateIconClick();
                                                }
                                            }}
                                        />
                                    </>
                                )}

                                {isAdd !== true && isEdit !== true && (
                                    <IconButton
                                        autoFocus
                                        title={i18next.t("template.newTemplate")}
                                        iconProps={{ iconName: "Add", style: { fontSize: "20px" } }}
                                        style={{ color: "blueviolet", float: "right", marginRight: "30px" }}
                                        onClick={() => addTemplate()}
                                    />
                                )}
                            </header>

                            <div className={isAdd || isEdit ? styles.TemplateContentContainerInAddEditMode : styles.TemplateContentContainer}>
                                {isEdit || isAdd ? (
                                    <div className={styles.templateContentInput}>
                                        <div className={styles.inputDivs}>
                                            <h5 style={{ alignSelf: "center", width: window.innerWidth < 1500 ? "10%" : "12%" }}>
                                                {i18next.t("template.title")}
                                            </h5>
                                            <input
                                                value={userPromptValues[0].title}
                                                onChange={handleTitleChange}
                                                placeholder={i18next.t("template.titlePlaceHolder")}
                                                className={styles.templateTitle}
                                                autoFocus
                                            />
                                        </div>
                                        <div className={styles.inputDivs}>
                                            <h5 style={{ alignSelf: "center", width: "10%", marginRight: "10px" }}>{i18next.t("template.prompt")}</h5>
                                            <textarea
                                                draggable={false}
                                                value={userPromptValues[0].prompt}
                                                onChange={handleChange}
                                                placeholder={i18next.t("template.promptPlaceHolder")}
                                                rows={10}
                                                cols={150}
                                                className={styles.templatePromptInput}
                                            />
                                        </div>
                                        {renderedInputBoxKeys.length > 0 && (
                                            <div className={styles.inputDivs}>
                                                <h5 style={{ paddingRight: "10px", alignSelf: "center", width: "12%" }}>{i18next.t("template.value")}</h5>
                                                <div className={styles.promptValues}>
                                                    {renderedInputBoxKeys.map((key, index) => (
                                                        <div
                                                            className="fields"
                                                            key={index}
                                                            style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
                                                        >
                                                            <label style={{ marginLeft: "20px", marginRight: "10px", alignSelf: "center" }}>{key}:</label>
                                                            <input
                                                                type="text"
                                                                placeholder={`${key}...`}
                                                                value={fields[key] || ""}
                                                                onChange={e => handleFieldChange(e, key)}
                                                            />
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        )}
                                        <div className={styles.previewDiv}>
                                            <h5 style={{ paddingRight: "10px", alignSelf: "center", width: "12%" }}>{i18next.t("template.preview")}</h5>
                                            <div className={styles.previewContent}>
                                                <RenderPreviewText />
                                            </div>
                                        </div>
                                        {ConfirmationDialogVisible && (
                                            <Layer>
                                                <Popup role="dialog" aria-modal="true" onDismiss={handleCancelConfirmation} className={popupStyles.root}>
                                                    <Overlay />
                                                    <FocusTrapZone>
                                                        <div role="document" className={popupStyles.content}>
                                                            <div>
                                                                <IconButton style={{ color: "red" }} iconProps={{ iconName: "WarningSolid" }} />
                                                                <IconButton
                                                                    style={{ color: "green", float: "right" }}
                                                                    iconProps={{ iconName: "Cancel" }}
                                                                    onClick={handleCancelConfirmation}
                                                                />
                                                                <span
                                                                    style={{
                                                                        padding: "5px",
                                                                        fontSize: window.innerWidth < 1000 ? "12px" : "15px",
                                                                        fontWeight: "bold"
                                                                    }}
                                                                >
                                                                    {i18next.t("template.confirmationTitle")}
                                                                </span>
                                                            </div>
                                                            <span style={{ marginTop: "25px", fontSize: window.innerWidth < 1000 ? "12px" : "15px" }}>
                                                                <h4> {i18next.t("template.confirmationContent")} </h4>
                                                            </span>
                                                            <div style={{ display: "flex", paddingTop: "25px", justifyContent: "flex-end" }}>
                                                                <PrimaryButton
                                                                    onClick={() => {
                                                                        setConfirmationDialogVisible(false);
                                                                        setIsEdit(false);
                                                                        setIsAdd(false);
                                                                        setEditingIndex(-1);
                                                                        setIsHover(-1);
                                                                    }}
                                                                    text={i18next.t("template.confirm")}
                                                                />
                                                                <DefaultButton
                                                                    onClick={() => setConfirmationDialogVisible(false)}
                                                                    text={i18next.t("template.cancel")}
                                                                    style={{ marginLeft: "10px" }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </FocusTrapZone>
                                                </Popup>
                                            </Layer>
                                        )}
                                    </div>
                                ) : (
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            marginTop: "15px",
                                            flexWrap: "wrap",
                                            maxHeight: "70vh",
                                            rowGap: "5vh",
                                            columnGap: "5vh"
                                        }}
                                    >
                                        {templateContents.length === 0 ? (
                                            <p className={styles.emptyResponseTemplateContainer}> Please Add Template </p>
                                        ) : (
                                            Array.isArray(templateContents) &&
                                            templateContents.map((e, i) => (
                                                <div
                                                    key={i}
                                                    onMouseEnter={() => {
                                                        setIsHover(i);
                                                    }}
                                                    onMouseLeave={() => {
                                                        setIsHover(-1);
                                                    }}
                                                    className={styles.templateZoom}
                                                >
                                                    <div
                                                        key={i}
                                                        className={styles.responseTemplateContainer}
                                                        style={{
                                                            border: selectedTemplate === i + 1 ? "4px solid #4396d0" : "1px solid black",
                                                            backgroundColor: selectedTemplate === i + 1 ? "#dce6f7" : "white",
                                                            padding: "3%",
                                                            resize: "none",
                                                            display: "flex",
                                                            flexDirection: "column"
                                                        }}
                                                        onClick={() => handleTemplateClick(i + 1)}
                                                    >
                                                        <h4 style={{ color: "#2d3feb", marginTop: "0px", marginBottom: "10px" }} className={styles.promptTitle}>
                                                            {templateContents[i].template[0].title}
                                                        </h4>
                                                        {Array.isArray(e.template) &&
                                                            e.template.map(element => (
                                                                <div className={styles.promptContent} key={i}>
                                                                    {element.previewPrompt}
                                                                </div>
                                                            ))}
                                                    </div>

                                                    {isHover !== -1 && isHover === i && (
                                                        <div style={{ display: "flex", flexDirection: "column", marginRight: "10px" }}>
                                                            <IconButton
                                                                style={{ color: "black" }}
                                                                title={i18next.t("template.apply")}
                                                                iconProps={{ iconName: "CheckMark", style: { fontWeight: "bold" } }}
                                                                onClick={() => {
                                                                    sendTemplateContents(templateContents[i].template[0]);
                                                                }}
                                                            />
                                                            <IconButton
                                                                style={{ color: "black" }}
                                                                title={i18next.t("template.edit")}
                                                                iconProps={{ iconName: "Edit", style: { fontWeight: "bold" } }}
                                                                onClick={() => {
                                                                    handleEditClick(i);
                                                                }}
                                                            />
                                                            {e.pk !== "default@ai-works" && (
                                                                <IconButton
                                                                    style={{ color: "black" }}
                                                                    title={i18next.t("template.delete")}
                                                                    iconProps={{ iconName: "Delete", style: { fontWeight: "bold" } }}
                                                                    onClick={() => {
                                                                        handleDeleteIconClick(templateContents[i].id);
                                                                    }}
                                                                />
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                            ))
                                        )}
                                        {deleteConfirmationDialogVisible && (
                                            <Layer>
                                                <Popup role="dialog" aria-modal="true" onDismiss={handleCancelDelete} className={popupStyles.root}>
                                                    <Overlay />
                                                    <FocusTrapZone>
                                                        <div role="document" className={popupStyles.content}>
                                                            <div>
                                                                <IconButton style={{ color: "red" }} iconProps={{ iconName: "WarningSolid" }} />
                                                                <IconButton
                                                                    style={{ color: "green", float: "right" }}
                                                                    iconProps={{ iconName: "Cancel" }}
                                                                    onClick={handleCancelDelete}
                                                                />
                                                                <span
                                                                    style={{
                                                                        padding: "5px",
                                                                        fontSize: window.innerWidth < 1000 ? "12px" : "15px",
                                                                        fontWeight: "bold"
                                                                    }}
                                                                >
                                                                    {i18next.t("template.confirmDeleteTitle")}
                                                                </span>
                                                            </div>
                                                            <span style={{ marginTop: "25px", fontSize: window.innerWidth < 1000 ? "12px" : "15px" }}>
                                                                {i18next.t("template.confirmDeleteMessage")}
                                                            </span>
                                                            <div style={{ display: "flex", paddingTop: "25px", justifyContent: "flex-end" }}>
                                                                <PrimaryButton onClick={handleConfirmDelete} text={i18next.t("template.confirm")} />
                                                                <DefaultButton
                                                                    onClick={handleCancelDelete}
                                                                    text={i18next.t("template.cancel")}
                                                                    style={{ marginLeft: "10px" }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </FocusTrapZone>
                                                </Popup>
                                            </Layer>
                                        )}
                                    </div>
                                )}

                                {isEdit || isAdd ? (
                                    <div className={styles.templateActions}>
                                        <>
                                            <IconButton
                                                title={i18next.t("template.close")}
                                                style={{ color: "blueviolet", float: "right" }}
                                                iconProps={{ iconName: "Cancel", style: { fontSize: "20px" } }}
                                                onClick={() => {
                                                    if (isAdd || isEdit) {
                                                        checkContentsEntered();
                                                    } else {
                                                        handleTemplateIconClick();
                                                    }
                                                }}
                                            />
                                            <IconButton
                                                style={{
                                                    float: "right",
                                                    marginTop: "20px",
                                                    color: "blueviolet"
                                                }}
                                                title={i18next.t("template.save")}
                                                iconProps={{ iconName: "Save", style: { fontSize: "20px" } }}
                                                onClick={() => {
                                                    setIsAdd(false);
                                                    setIsEdit(false);
                                                    handleSaveClick();
                                                }}
                                                disabled={
                                                    (userPromptValues[0].prompt.trim() === templateContents[editingIndex!]?.template[0].prompt.trim() &&
                                                        userPromptValues[0].title.trim() === templateContents[editingIndex!]?.template[0].title.trim() &&
                                                        deepEqual(userPromptValues[0].fields, templateContents[editingIndex!]?.template[0].fields)) ||
                                                    userPromptValues[0].title.trim().length === 0 ||
                                                    userPromptValues[0].prompt.trim().length === 0
                                                }
                                            />
                                            <div style={{ color: "blueviolet", float: "right" }}>
                                                <TooltipHost
                                                    content={
                                                        <>
                                                            <p>
                                                                <b>{i18next.t("template.infoTitle")}</b>
                                                            </p>
                                                            <p>
                                                                {i18next.t("template.infoEg")} {"{ "}
                                                                <span style={{ color: "red" }}>{i18next.t("template.infoSubject")}</span>
                                                                {" }"}
                                                            </p>
                                                        </>
                                                    }
                                                    directionalHint={DirectionalHint.bottomRightEdge}
                                                >
                                                    <IconButton
                                                        style={{ color: "blueviolet", float: "right", marginTop: "20px" }}
                                                        title={i18next.t("template.info")}
                                                        iconProps={{ iconName: "Info", style: { fontSize: "20px" } }}
                                                    />
                                                </TooltipHost>
                                            </div>
                                        </>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </FocusTrapZone>
            </Popup>
        </Layer>
    );
};

export default DynamicTemplatePrompt;
