import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";
type TokenValues = {
    exp: number;
    isInchargeOfAiWorks: string;
    userId: string;
    backendUri: string;
    products: [];
    supportEnvHost: string;
};
interface UpdateDetails {
    updatesEN: {
        [key: string]: string[];
    };
    updatesJA: {
        [key: string]: string[];
    };
}

interface AppDetails {
    id: string;
    information: string;
    aimodel_admin: { [key: string]: string };
    locales: { [key: string]: string };
    aimodel_user: { [key: string]: string };
    product_manual_categories: { [key: string]: string };
    faq_categories: { [key: string]: string };
    document_categories: { [key: string]: string };
    wap_doc_categories: { [key: string]: string };
    client_doc_categories: { [key: string]: string };
    retrieval_mode: { [key: string]: string };
    feedbacktype: { [key: string]: string };
    semantic: { [key: string]: string };
    updates: {
        [key: string]: string[];
    };
    commonEn: {
        layout: {
            wap: string;
            headerTitle: string;
            manual: string;
            feedbackForm: string;
            faqAppName: string;
            faqAppLink: string;
            manualAppName: string;
            manualAppLink: string;
            bannerText: string;
            chat: string;
            feedbacks: string;
            reports: string;
        };
        chat: {
            title: string;
            input: string;
            inputPlaceholder: string;
            subtitle: string;
            productFilterTitle: string;
            productFilterText: string;
            documenttype: string;
            ALL: string;
            Private: string;
            Public: string;
            chatModel: string;
            today: string;
            last7days: string;
            last30days: string;
            feedbackuser: string;
            warning: string;
            ok: string;
        };
        example: {
            example1: string;
            example1Category: string;
            example1DocType: string;
            example2: string;
            example2Category: string;
            example2DocType: string;
            example3: string;
            example3Category: string;
            example3DocType: string;
        };
        settings: {
            title: string;
        };
        answer: {
            good: string;
            bad: string;
            thoughtProcess: string;
            supportingContent: string;
            additionalFeedback: string;
            goodFeedbackText: string;
            badFeedbackText: string;
            notTrue: string;
            notHelpful: string;
            harmful: string;
            submitButton: string;
            chatFeedback: string;
            citations: string;
            followupQuestions: string;
        };
        analysis: {
            thoughtProcess: string;
            supportingContent: string;
            citation: string;
        };
        supportingContent: {
            content: string;
            citationSource: string;
            originalSource: string;
            score: string;
            rerankerScore: string;
            documenttype: string;
            openinNewTab: string;
            openinNewWindow: string;
            noSupportingContent: string;
        };
        appsettings: {
            save: string;
            appInfo: string;
            updateInfo: string;
        };
        clearChat: {
            clearChat: string;
            newChat: string;
        };
        questionInput: {
            tooltip: string;
        };
        regenerate: {
            regenerate: string;
        };
    };
    commonJa: {
        layout: {
            wap: string;
            headerTitle: string;
            manual: string;
            feedbackForm: string;
            faqAppName: string;
            faqAppLink: string;
            manualAppName: string;
            manualAppLink: string;
            bannerText: string;
            chat: string;
            feedbacks: string;
            reports: string;
        };
        chat: {
            title: string;
            input: string;
            inputPlaceholder: string;
            subtitle: string;
            productFilterTitle: string;
            productFilterText: string;
            documenttype: string;
            ALL: string;
            Private: string;
            Public: string;
            chatModel: string;
            today: string;
            last7days: string;
            last30days: string;
            feedbackuser: string;
            warning: string;
            ok: string;
        };
        example: {
            example1: string;
            example1Category: string;
            example1DocType: string;
            example2: string;
            example2Category: string;
            example2DocType: string;
            example3: string;
            example3Category: string;
            example3DocType: string;
        };
        settings: {
            title: string;
        };
        answer: {
            good: string;
            bad: string;
            thoughtProcess: string;
            supportingContent: string;
            additionalFeedback: string;
            goodFeedbackText: string;
            badFeedbackText: string;
            notTrue: string;
            notHelpful: string;
            harmful: string;
            submitButton: string;
            chatFeedback: string;
            citations: string;
            followupQuestions: string;
        };
        analysis: {
            thoughtProcess: string;
            supportingContent: string;
            citation: string;
        };
        supportingContent: {
            content: string;
            citationSource: string;
            originalSource: string;
            score: string;
            rerankerScore: string;
            documenttype: string;
            openinNewTab: string;
            openinNewWindow: string;
            noSupportingContent: string;
        };
        clearChat: {
            clearChat: string;
            newChat: string;
        };
        questionInput: {
            tooltip: string;
        };
        regenerate: {
            regenerate: string;
        };
        appsettings: {
            save: string;
            appInfo: string;
            updateInfo: string;
        };
    };
}
interface UserDetails {
    userId: string;
    docValue: string;
    product: string;
    locale: string;
    aimodel: string;
    isSidebarOpen: string;
    apptype: string;
    isWap: string;
    corp_id: string;
    subProduct: string;
    isInchargeOfAiWorks: string;
    chatTourDone: string;
    answerTourDone: string;
}

export let backendUri = "";

export let productList = [];

export let isError = false;

export let supportEnvBoard = "";

function getHeaders(): HeadersInit {
    const headers: Record<string, string> = {
        "Content-Type": "application/json"
    };
    if (jwtToken && jwtToken != "" && jwtToken != undefined) {
        headers["Authorization"] = `Bearer ${jwtToken}`;
    }
    return headers;
}

async function fetchAppInfo(): Promise<AppDetails | any> {
    if (isError) {
        return;
    }
    const queryParams = new URLSearchParams();
    queryParams.append("load_products", JSON.stringify(true));
    if (productList?.length === 1 && productList[0] === "ALL") {
        queryParams.append("product_list", JSON.stringify([]));
    } else {
        queryParams.append("product_list", JSON.stringify(productList));
    }
    const response = await fetch("/load-app-info?" + queryParams, {
        method: "GET",
        headers: getHeaders()
    });
    if (!response.ok) {
        const result = await response.json();
        alert(`${result?.error}. Please login again`);
        if (result.redirect) {
            window.location.href = result.redirect;
        }
    }
    return await response.json();
}

async function fetchUserInfo(): Promise<UserDetails | any> {
    if (isError) {
        return;
    }
    const response = await fetch("/load-user-info", {
        method: "POST",
        headers: getHeaders(),
        body: JSON.stringify({})
    });
    if (!response.ok) {
        const result = await response.json();
        alert(`${result?.error}. Please login again`);
        if (result.redirect) {
            window.location.href = result.redirect;
        }
    }
    return await response.json();
}

async function fetchUpdateInfo(): Promise<UpdateDetails | any> {
    if (isError) {
        return;
    }
    const response = await fetch("/load-update-info", {
        method: "GET",
        headers: getHeaders()
    });
    if (!response.ok) {
        const result = await response.json();
        alert(`${result?.error}. Please login again`);
        if (result.redirect) {
            window.location.href = result.redirect;
        }
    }
    return await response.json();
}

async function getJwttoken(): Promise<string> {
    const jwtCookie = extractValidJwtToken(document.cookie);
    if (jwtCookie != "" && jwtCookie != undefined && jwtCookie.length > 5) {
        const tokenValues = jwtDecode(jwtCookie || "") as TokenValues;
        if (tokenValues?.exp > Date.now() / 1000 && tokenValues?.userId?.length > 0) {
            backendUri = tokenValues.backendUri ?? null;
            productList = tokenValues.products ?? [];
            return jwtCookie;
        } else {
            Cookies.remove("token", { path: "/" });
            Cookies.remove("token", { path: "/assistant" });
            return "";
        }
    }
    if (jwtCookie) {
        return jwtCookie;
    } else {
        return "";
    }
}

function extractValidJwtToken(cookieString: string | undefined) {
    const cookies = cookieString?.split(";") ?? [];
    let validToken = null;

    for (const cookie of cookies) {
        const trimmedCookie = cookie.trim();
        if (trimmedCookie.startsWith("token=")) {
            const tokenParts = trimmedCookie.split("=");
            const token = tokenParts[1];
            if (token !== "") {
                validToken = token;
                break;
            }
        }
    }
    return validToken;
}

async function fetchBackendValue() {
    try {
        const response = await fetch("/assistant/home_env", {
            method: "GET"
        });
        const data = await response?.json();
        supportEnvBoard = data.support_env_board;
        return data.home_env_url;
    } catch (error) {
        console.log("Error fetching Home URL value:", error);
    }
}

async function validateTokenAndInitialize() {
    if (jwtToken && jwtToken != "" && jwtToken != undefined) {
        const tokenValues = jwtDecode(jwtToken ?? "") as TokenValues;
        if (tokenValues?.exp < Date.now() / 1000) {
            isError = true;
            alert(
                "セッションが切れました。@SUPPORTにログイン後、本URLに再度アクセスしてください。\n-----\nSession Expired...Please login via @SUPPORT and try again."
            );
            window.location.href = supportEnvHost;
        }
    } else {
        isError = true;
        alert(
            "セッションが切れました。@SUPPORTにログイン後、本URLに再度アクセスしてください。\n-----\nInvalid Session...Please login via @SUPPORT and try again."
        );
        window.location.href = supportEnvHost;
    }
}

export const supportEnvHost = await fetchBackendValue();

export const jwtToken = await getJwttoken();

await validateTokenAndInitialize();

export const appInfo = await fetchAppInfo();

export const userInfo = await fetchUserInfo();

export const updateInfo = await fetchUpdateInfo();
