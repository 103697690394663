import { DocumentAddRegular } from "@fluentui/react-icons";
import { PrimaryButton } from "@fluentui/react";
import styles from "./ClearChatButton.module.css";
import i18next from "../../i18n/i18n";

interface Props {
    className?: string;
    onClick: () => void;
    disabled?: boolean;
}

export const ClearChatButton = ({ className, disabled, onClick }: Props) => {
    return (
        <div className={`${styles.container} ${className ?? ""}`}>
            <PrimaryButton id="ClearChat" disabled={disabled} onClick={onClick} className={styles.primaryButton}>
                <DocumentAddRegular style={{ padding: "1px", marginRight: "5px" }} />
                {i18next.t("clearChat.newChat")}
            </PrimaryButton>
        </div>
    );
};
