import { useState, useEffect } from "react";
import { fetchAppInfo, saveAppSettings, fetchUpdateInfo, saveAppUpdates } from "../../api";
import { PrimaryButton } from "@fluentui/react";
import SplitPane from "split-pane-react";
import "split-pane-react/esm/themes/default.css";
import "chart.js/auto";
import i18next from "../../i18n/i18n";

const Settings = () => {
    const [textareaValue, setTextareaValue] = useState<string>("");
    const [isLoading, setIsLoading] = useState(false);
    const [updateTextareaValue, setUpdateTextareaValue] = useState<string>("");
    const [isUpdateLoading, setisUpdateLoading] = useState(false);

    const Spinner = () => {
        return (
            <div className="full-page-spinner">
                <div className="spinner"></div>
            </div>
        );
    };

    const loadAppSettings = async () => {
        setIsLoading(true);
        try {
            await fetchAppInfo()
                .then(response => {
                    return response.json();
                })
                .then(result => {
                    const jsonString = JSON.stringify(result, null, 2);
                    setTextareaValue(jsonString);
                })
                .catch(error => {
                    console.error("Error fetching App Settings data:", error);
                });
        } catch (error) {
            console.error("Error fetching App Settings data:", error);
        }
        setIsLoading(false);
    };

    const loadUpdateSettings = async () => {
        setisUpdateLoading(true);
        try {
            await fetchUpdateInfo()
                .then(response => {
                    return response.json();
                })
                .then(result => {
                    const resultMap = { updatesEN: JSON.parse(result["updatesEN"]), updatesJA: JSON.parse(result["updatesJA"]) };
                    const jsonString = JSON.stringify(resultMap, null, 2);
                    setUpdateTextareaValue(jsonString);
                })
                .catch(error => {
                    console.error("Error fetching App Settings data:", error);
                });
        } catch (error) {
            console.error("Error fetching App Settings data:", error);
        }
        setisUpdateLoading(false);
    };

    const updateAppSettings = async () => {
        setIsLoading(true);
        try {
            await saveAppSettings(textareaValue)
                .then(response => {
                    return response.json();
                })
                .then(() => {
                    window.location.reload();
                })
                .catch(error => {
                    console.error("Error saving App Settings data:", error);
                });
        } catch (error) {
            console.error("Error saving App Settings data:", error);
        }
        setIsLoading(false);
    };

    const saveUpdates = async () => {
        setisUpdateLoading(true);
        try {
            await saveAppUpdates(updateTextareaValue)
                .then(response => {
                    return response.json();
                })
                .then(() => {
                    window.location.reload();
                })
                .catch(error => {
                    console.error("Error saving App Settings data:", error);
                });
        } catch (error) {
            console.error("Error saving App Settings data:", error);
        }
        setisUpdateLoading(false);
    };

    useEffect(() => {
        // history.replaceState({}, "", "/#/settings");
        loadAppSettings();
        loadUpdateSettings();
    }, []);

    const [sizes, setSizes] = useState([50, 50]);

    const layoutCSS = {
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    };
    const sashRender = (size: number, isVertical: boolean) => (
        <div
            style={{
                width: isVertical ? "3px" : "100%",
                height: isVertical ? "100%" : "3px",
                background: "#ccc"
            }}
        />
    );
    return (
        <div style={{ height: "88vh", display: "flex", flexDirection: "column" }}>
            {(isLoading || isUpdateLoading) && <Spinner />}
            <SplitPane split="vertical" sizes={sizes} onChange={setSizes} sashRender={sashRender}>
                <div style={{ ...layoutCSS, display: "flex", flexDirection: "column", height: "100%" }}>
                    <span style={{ fontWeight: "bold", fontSize: "17px" }}>{i18next.t("appsettings.appInfo")}</span>
                    <textarea
                        value={textareaValue}
                        onChange={e => setTextareaValue(e.target.value)}
                        style={{ flex: 1, width: "100%", margin: "15px auto 20px", height: "70vh" }}
                        spellCheck={false}
                    />

                    <PrimaryButton
                        style={{ padding: "5px", width: "150px", borderRadius: "5px", height: "34px", alignSelf: "center" }}
                        text={i18next.t("appsettings.save")}
                        onClick={updateAppSettings}
                        disabled={isLoading}
                    />
                </div>
                <div style={{ ...layoutCSS, display: "flex", flexDirection: "column", height: "100%" }}>
                    <span style={{ fontWeight: "bold", fontSize: "17px" }}>{i18next.t("appsettings.updateInfo")}</span>
                    <textarea
                        value={updateTextareaValue}
                        onChange={e => setUpdateTextareaValue(e.target.value)}
                        style={{ flex: 1, width: "100%", margin: "15px auto 20px", height: "70vh" }}
                        spellCheck={false}
                    />

                    <PrimaryButton
                        style={{ padding: "5px", width: "150px", borderRadius: "5px", height: "34px", alignSelf: "center" }}
                        text={i18next.t("appsettings.save")}
                        onClick={saveUpdates}
                        disabled={isUpdateLoading}
                    />
                </div>
            </SplitPane>
        </div>
    );
};

export default Settings;
