import { IconButton } from "@fluentui/react";
import styles from "./UserChatMessage.module.css";
import { useEffect, useState } from "react";
import { ChatAppResponse, copySharedChatApi, deleteFollowupConversation } from "../../api";
import i18next from "i18next";
import { v4 as uuidv4 } from "uuid";

interface Props {
    message: string;
    onSend?: (question: string, index: number | undefined) => void;
    conversation?: { userName: string; conversationId: string; groupId: string; title: string; conversationDate: string }[];
    index?: number;
    answers?: [user: string, response: ChatAppResponse][];
    streamedAnswers?: [user: string, response: ChatAppResponse][];
    primaryReference?: Map<number, string>;
    onEditClick?: (checked: boolean) => void;
    IsOnEdit?: boolean;
    isChatShared?: boolean;
    isAdminUser?: boolean;
    selectedQuestion?: boolean;
    applyRestriction?: boolean;
    reference?: any;
}

export const UserChatMessage = ({
    onSend,
    message,
    conversation,
    index,
    answers,
    streamedAnswers,
    primaryReference,
    onEditClick,
    IsOnEdit,
    isChatShared,
    isAdminUser,
    selectedQuestion,
    applyRestriction,
    reference
}: Props) => {
    const [isEdit, setIsEdit] = useState(IsOnEdit);
    const [isHover, setIsHover] = useState(false);
    const [editedMessage, setEditedMessage] = useState(message);
    const [question, setQuestion] = useState<string>(message);
    const [conversationDate, setConversationDate] = useState<string>("");
    const [isCheckIconDisabled, setIsCheckIconDisabled] = useState(true);
    const [initialMessage, setInitialMessage] = useState(message);

    useEffect(() => {
        setEditedMessage(message);
    }, [message]);

    useEffect(() => {
        setIsEdit(false);
    }, [applyRestriction]);

    useEffect(() => {
        setIsCheckIconDisabled(!editedMessage || editedMessage.trim().length === 0 || editedMessage.trim() === initialMessage);
    }, [editedMessage, initialMessage]);

    const handleEditClick = async (index: number) => {
        if (onEditClick) {
            setIsEdit(true);
            onEditClick(true);

            const conversationDetails = conversation?.[index];
            if (conversationDetails) {
                const { conversationDate, title } = conversationDetails;
                setConversationDate(conversationDate);
                isEdit ? setEditedMessage(title) : setEditedMessage(message);
            }
            setInitialMessage(message);
            setIsCheckIconDisabled(true);
        }
    };

    const copySharedChat = async (sessionvalue: string) => {
        try {
            const newSessionId = sessionStorage.getItem("sessionId");
            await copySharedChatApi(sessionvalue, newSessionId)
                .then(response => {
                    return response.json();
                })
                .catch(error => {
                    console.error("Error fetching data:", error);
                });
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const handleCheckClick = async (index: number | undefined) => {
        setIsEdit(false);
        setQuestion(editedMessage);

        if (isChatShared) {
            const oldSessionID = sessionStorage.getItem("sessionId") ?? "";
            const newSessionId = String(uuidv4());
            sessionStorage.setItem("sessionId", newSessionId);
            await copySharedChat(oldSessionID);
        }

        answers ? answers.splice(index as number) : [];
        streamedAnswers ? streamedAnswers.splice(index as number) : [];
        primaryReference ? primaryReference.delete(index as number) : {};

        try {
            await deleteFollowupConversation(conversationDate);
        } catch (error) {
            console.error("Error deleting followup conversation:", error);
        }

        if (onSend) {
            onSend(editedMessage, index);
        }
    };

    const preprocessedValue = editedMessage.replace(/^\s+/, "");
    const formattedMessage = preprocessedValue.replace(/\n/g, "<br/>");

    return (
        <div
            className={styles.container}
            onMouseEnter={() => {
                setIsHover(true);
            }}
            onMouseLeave={() => {
                setIsHover(false);
            }}
        >
            {selectedQuestion && isEdit && IsOnEdit ? (
                <div key={index}>
                    <textarea
                        key={index}
                        value={preprocessedValue}
                        className={styles.message}
                        style={{
                            fontFamily: "sans-serif",
                            fontSize: "16px",
                            width: reference?.current?.offsetWidth ? `${reference?.current?.offsetWidth}px` : "700px",
                            height: "150px",
                            resize: "none"
                        }}
                        onChange={e => {
                            setEditedMessage(e.target.value);
                        }}
                    >
                        {question}
                    </textarea>
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <IconButton
                            disabled={isCheckIconDisabled}
                            title={i18next.t("chat.save")}
                            iconProps={{ iconName: "CheckMark" }}
                            onClick={() => {
                                handleCheckClick(index);
                            }}
                        />
                        <span>
                            <IconButton
                                title={i18next.t("chat.cancel")}
                                iconProps={{ iconName: "cancel" }}
                                onClick={() => {
                                    setIsEdit(false);
                                    setEditedMessage(message);
                                }}
                            />
                        </span>
                    </div>
                </div>
            ) : (
                <div key={index}>
                    <div className={styles.message} dangerouslySetInnerHTML={{ __html: formattedMessage }}></div>
                    {!isAdminUser && (
                        <div>
                            <IconButton
                                title={i18next.t("chat.edit")}
                                style={{ visibility: isHover ? "visible" : "hidden" }}
                                iconProps={{ iconName: "Edit" }}
                                onClick={() => index !== undefined && handleEditClick(index)}
                                disabled={applyRestriction}
                            />
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};
