import { useMemo, useState, useCallback, useEffect } from "react";
import { mergeStyleSets, Stack, IconButton, Popup, Layer, FocusTrapZone, DefaultButton, Checkbox, Icon, Text } from "@fluentui/react";
import DOMPurify from "dompurify";
import styles from "./Answer.module.css";
import i18next from "../../i18n/i18n";
import { ChatAppResponse, getCitationFilePath, Feedback, saveSharedChatValueApi, saveUserDetails } from "../../api";
import { parseAnswerToHtml } from "./AnswerParser";
import { AnswerIcon } from "./AnswerIcon";
import { useBoolean } from "@fluentui/react-hooks";
import { TextField } from "@fluentui/react/lib/TextField";
import { Separator } from "@fluentui/react/lib/Separator";
import { LockIcon } from "../LockIcon/LockIcon";
import { Toast, ToastTitle, Toaster, useId, useToastController } from "@fluentui/react-components";
import { userInfo } from "../../authConfig";
import { useSetState } from "react-use";
import Joyride, { STATUS, Step } from "react-joyride";

interface Props {
    answer: ChatAppResponse;
    updateStreamedAnswer?: any;
    isSelected?: boolean;
    isStreaming: boolean;
    onCitationClicked: (filePath: string, citationIndex: number) => void;
    onThoughtProcessClicked: () => void;
    onSupportingContentClicked: () => void;
    onFollowupQuestionClicked?: (question: string) => void;
    onFeedbackSubmitClicked: (feedback: Feedback) => void;
    showFollowupQuestions?: boolean;
    primaryId: string;
    onClickLikeDislike: () => void;
    isAdminUser?: boolean;
    doctype?: boolean;
    displayLink?: () => void;
    isLastAnswer: boolean;
    isFeedbackPage: boolean;
}

const popupStyles = mergeStyleSets({
    root: {
        background: "rgba(0, 0, 0, 0.5)",
        bottom: "0",
        left: "0",
        position: "absolute",
        right: "0",
        top: "0"
    },
    content: {
        background: "white",
        left: "50%",
        maxWidth: "1000px",
        position: "absolute",
        top: "50%",
        transform: "translate(-50%, -50%)",
        borderRadius: "15px",
        border: "2px solid",
        borderColor: "grey",
        padding: "15px"
    }
});

const stackTokens = { childrenGap: 10, padding: 25 };

export const Answer = ({
    answer,
    updateStreamedAnswer,
    isSelected,
    isStreaming,
    primaryId,
    onCitationClicked,
    onThoughtProcessClicked,
    onSupportingContentClicked,
    onFollowupQuestionClicked,
    onFeedbackSubmitClicked,
    showFollowupQuestions,
    onClickLikeDislike,
    doctype,
    isAdminUser,
    displayLink,
    isLastAnswer,
    isFeedbackPage
}: Props) => {
    const followupQuestions = answer?.choices[0]?.context?.followup_questions;
    const dataPoints = answer?.choices[0]?.context?.data_points;
    const messageContent = typeof answer.choices[0]?.message?.content === "string" ? answer.choices[0]?.message?.content : "";
    const fileMapping = answer?.choices[0]?.context.file_mapping;
    const parsedAnswer = useMemo(() => parseAnswerToHtml(messageContent, isStreaming, onCitationClicked, dataPoints, fileMapping), [answer]);

    const sanitizedAnswerHtml = DOMPurify.sanitize(parsedAnswer.answerHtml);
    const [isLikeVisible, { setTrue: showLike, setFalse: hideLike }] = useBoolean(true);
    const [isDisLikeVisible, { setTrue: showDisLike, setFalse: hideDisLike }] = useBoolean(true);
    const [isLikePopupVisible, { setTrue: showLikePopup, setFalse: hideLikePopup }] = useBoolean(false);
    const [isDisLikePopupVisible, { setTrue: showDisLikePopup, setFalse: hideDisLikePopup }] = useBoolean(false);
    const [isSharChatPopupVisible, { setTrue: showSharChatPopup, setFalse: hideShareChatPopup }] = useBoolean(false);
    const [likeIconName, setLikeIconName] = useState("Like");
    const [disLikeIconName, setDisLikeIconName] = useState("Dislike");
    const [shareIconName, setShareIconName] = useState("Share");
    const [isLikeDisabled, setIsLikeDisabled] = useState(false);
    const [isDisLikeDisabled, setIsDisLikeDisabled] = useState(false);
    const [isCheckBoxOneChecked, setIsCheckBoxOneChecked] = useState(false);
    const [isCheckBoxTwoChecked, setIsCheckBoxTwoChecked] = useState(false);
    const [isCheckBoxThreeChecked, setIsCheckBoxThreeChecked] = useState(false);
    const [TextFieldDisLikeValue, setTextFieldDisLikeValue] = useState("");
    const [TextFieldLikeValue, setTextFieldLikeValue] = useState("");
    const [fieldDownVoteValue, setfieldDownVoteValue] = useState(0);

    const onChangeTextFieldValue = useCallback((event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setTextFieldDisLikeValue(newValue || "");
        fnDisableSubmitButton();
    }, []);
    function _onChangeCheckBoxone(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, isChecked?: boolean) {
        setIsCheckBoxOneChecked(!isCheckBoxOneChecked);
        setfieldDownVoteValue(!isCheckBoxOneChecked ? 1 : 0);
    }

    function _onChangeCheckBoxtwo(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, isChecked?: boolean) {
        setIsCheckBoxTwoChecked(!isCheckBoxTwoChecked);
        setfieldDownVoteValue(!isCheckBoxTwoChecked ? 2 : 0);
    }

    function _onChangeCheckBoxthree(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, isChecked?: boolean) {
        setIsCheckBoxThreeChecked(!isCheckBoxThreeChecked);
        setfieldDownVoteValue(!isCheckBoxThreeChecked ? 3 : 0);
    }

    const fnDisableSubmitButton = () => {
        if ((isCheckBoxOneChecked || isCheckBoxTwoChecked || isCheckBoxThreeChecked) && TextFieldDisLikeValue.length > 0) return false;
        else return true;
    };

    function copyToClipboard(href: string) {
        // Add UTM parameters to the chat share link
        const url = new URL(href);
        url.searchParams.set("utm_source", "aiworks");
        url.searchParams.set("utm_medium", "share_link");
        url.searchParams.set("utm_campaign", "chat_share");
        const chatShareLink = url.toString();
        const tempInput = document.createElement("input");
        tempInput.value = chatShareLink;
        document.body.appendChild(tempInput);
        tempInput.select();
        document.execCommand("copy");
        document.body.removeChild(tempInput);
    }

    const toasterId = useId("toaster");
    const { dispatchToast } = useToastController(toasterId);
    const notify = () =>
        dispatchToast(
            <Toast style={{ backgroundColor: "white", borderRadius: "5px", color: "green", width: "300px" }}>
                <ToastTitle style={{ color: "black", fontWeight: "bold" }}>{i18next.t("answer.chatLinkCopied")}</ToastTitle>
            </Toast>,
            { intent: "success" }
        );
    const saveSharedChatValue = async (sessionValue: string) => {
        try {
            await saveSharedChatValueApi(sessionValue, userInfo.corp_id)
                .then(response => {
                    return response.json();
                })
                .then(result => {
                    console.log(result);
                });
        } catch (error) {
            console.error(error);
        }
    };

    function parseQueryParams(queryvalue: string) {
        const queryparameter = new URLSearchParams(queryvalue);
        const params: { [key: string]: string } = {};

        for (const [key, value] of queryparameter.entries()) {
            params[key] = value;
        }

        return params;
    }

    const handleLikeShowHide = () => {
        setTextFieldLikeValue("");
        hideLikePopup();
    };

    const handleDisLikeShowHide = () => {
        setTextFieldDisLikeValue("");
        setIsCheckBoxOneChecked(false);
        setIsCheckBoxTwoChecked(false);
        setIsCheckBoxThreeChecked(false);
        setfieldDownVoteValue(0);
        hideDisLikePopup();
    };

    interface State {
        run: boolean;
        steps: Step[];
    }

    const [tourOne, setTourOne] = useSetState<State>({
        run: false,
        steps: [
            {
                content: i18next.t(`appTour.answerTour.shareIcon`),
                target: `#ShareIcon${primaryId}`,
                disableBeacon: true,
                floaterProps: {
                    disableAnimation: true,
                    autoOpen: true
                }
            },
            {
                content: i18next.t(`appTour.answerTour.likeIcon`),
                target: `#LikeIcon${primaryId}`
            },
            {
                content: i18next.t(`appTour.answerTour.disLikeIcon`),
                target: `#DisLikeIcon${primaryId}`
            }
        ]
    });

    const endTourOne = useCallback(() => {
        setTourOne(prevState => ({
            ...prevState,
            run: false
        }));
        saveUserSettings("", "", "", "", "", "", "", "", "true", "true", "", "", "", "");
        userInfo.answerTourDone = "true";
    }, []);

    const handleJoyrideCallback = (data: any) => {
        const { status } = data;
        const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

        if (finishedStatuses.includes(status)) {
            endTourOne();
        }
    };

    useEffect(() => {
        if (isLastAnswer && isStreaming === false && userInfo.answerTourDone === "false") {
            setTimeout(() => {
                setTourOne(prevState => ({
                    ...prevState,
                    run: userInfo.answerTourDone === "false" ? true : false
                }));
            }, 1500);
        }
    }, [isStreaming]);

    const saveUserSettings = async (
        docValue: string,
        productValue: string,
        locale: string,
        aimodel: string,
        isSidebarOpen: string,
        apptype: string,
        faqProduct: string,
        subProduct: string,
        chatTourDone: string,
        answerTourDone: string,
        productVersion: string,
        versionIndex: string,
        templateTourDone: string,
        faqVersion: string
    ) => {
        try {
            await saveUserDetails(
                docValue,
                productValue,
                locale,
                aimodel,
                isSidebarOpen,
                apptype,
                faqProduct,
                subProduct,
                chatTourDone,
                answerTourDone,
                productVersion,
                versionIndex,
                templateTourDone,
                faqVersion
            )
                .then(response => {
                    return response.json();
                })
                .catch(error => {
                    console.error("Error saving user data:", error);
                });
        } catch (error) {
            console.error("Error saving user data:", error);
        }
    };

    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <Joyride
                continuous
                steps={tourOne.steps}
                callback={data => {
                    if (!isStreaming) {
                        handleJoyrideCallback(data);
                    }
                }}
                run={tourOne.run}
                hideCloseButton
                scrollToFirstStep
                showSkipButton
                disableCloseOnEsc
                disableOverlayClose
                spotlightPadding={1}
                locale={{
                    next: i18next.t(`appTour.next`),
                    skip: <strong>{i18next.t(`appTour.skip`)}</strong>,
                    back: i18next.t(`appTour.back`),
                    last: i18next.t(`appTour.last`)
                }}
            />
            <Stack
                id={primaryId}
                className={`${styles.answerContainer} ${isSelected && styles.selected}`}
                style={doctype ? { backgroundColor: "rgb(240, 255, 240)" } : {}}
                verticalAlign="space-between"
            >
                <Stack.Item>
                    <Stack horizontal horizontalAlign="space-between">
                        <div>
                            <AnswerIcon
                                apptype={answer.choices[0].context.app_type}
                                language={answer.choices[0].context.language}
                                product={answer.choices[0].context.product_filter}
                                sub_product={answer.choices[0].context.sub_product_filter}
                                documenttype={answer.choices[0].context.document_category}
                                chatModel={answer.choices[0].context.chat_model}
                                feedbackCorpId={answer.choices[0].context.feedback_corp_id}
                                feedbackUser={answer.choices[0].context.feedback_user}
                                CorpId={answer.choices[0].context.corpId}
                                UserId={answer.choices[0].context.userId}
                                isFeedbackPage={isFeedbackPage}
                                productVersion={answer.choices[0].context.productVersion}
                                versionIndex={answer.choices[0].context.versionIndex}
                            />
                            {doctype && <LockIcon />}
                        </div>
                        <div>
                            {isAdminUser && (
                                <IconButton
                                    style={{ color: "black" }}
                                    iconProps={{ iconName: "Lightbulb" }}
                                    title={i18next.t("answer.thoughtProcess")}
                                    ariaLabel={i18next.t("answer.thoughtProcess")}
                                    onClick={() => onThoughtProcessClicked()}
                                    disabled={!answer.choices[0].context.thoughts?.length}
                                />
                            )}
                            {parsedAnswer?.citations?.length > 0 && (
                                <IconButton
                                    style={{ color: "black" }}
                                    iconProps={{ iconName: "ClipboardList" }}
                                    title={i18next.t("answer.supportingContent")}
                                    ariaLabel={i18next.t("answer.supportingContent")}
                                    onClick={() => onSupportingContentClicked()}
                                    disabled={!answer.choices[0].context.data_points?.length}
                                />
                            )}
                        </div>
                    </Stack>
                </Stack.Item>

                <Stack.Item grow>
                    <div className={styles.answerText} dangerouslySetInnerHTML={{ __html: sanitizedAnswerHtml }}></div>
                </Stack.Item>

                {!!parsedAnswer.citations.length && answer.choices[0].context?.app_type && (
                    <Stack.Item>
                        <Stack horizontal wrap tokens={{ childrenGap: 5 }}>
                            <span className={styles.citationLearnMore}>{i18next.t("answer.citations")}:</span>
                            {parsedAnswer.citations.map((x, i) => {
                                const path = getCitationFilePath(x);
                                const contentPath = x
                                    .replace(/^product-manuals-\d+\//, "product-manuals/")
                                    .replace(/^product-manuals\/[^\/]+\//, "product-manuals/");
                                return (
                                    <a key={i} className={styles.citation} title={contentPath} onClick={() => onCitationClicked(path, i)}>
                                        {`${++i}. ${contentPath.replace(
                                            /^[^/]*\//,
                                            answer.choices[0].context.app_type === "Manuals" ? "product_manual/" : "faq/"
                                        )}`}
                                    </a>
                                );
                            })}
                        </Stack>
                    </Stack.Item>
                )}

                {!!followupQuestions?.length && showFollowupQuestions && onFollowupQuestionClicked && (
                    <Stack.Item>
                        <Stack horizontal wrap className={`${parsedAnswer.citations.length ? styles.followupQuestionsList : ""}`} tokens={{ childrenGap: 6 }}>
                            <span className={styles.followupQuestionLearnMore}>{i18next.t("answer.followupQuestions")}:</span>
                            {followupQuestions.map((x, i) => {
                                return (
                                    <a key={i} className={styles.followupQuestion} title={x} onClick={() => onFollowupQuestionClicked(x)}>
                                        {`${x}`}
                                    </a>
                                );
                            })}
                        </Stack>
                    </Stack.Item>
                )}
                <Stack.Item>
                    <Stack horizontal horizontalAlign="end">
                        <IconButton
                            id={`ShareIcon${primaryId}`}
                            style={{ color: "black" }}
                            iconProps={{ iconName: shareIconName }}
                            title={i18next.t("chat.shareChat")}
                            ariaLabel={i18next.t("chat.shareChatURL")}
                            onClick={() => showSharChatPopup()}
                        />
                        {isLikeVisible && (
                            <IconButton
                                id={`LikeIcon${primaryId}`}
                                style={{ color: "black" }}
                                iconProps={{ iconName: likeIconName }}
                                title={i18next.t("answer.good")}
                                ariaLabel={i18next.t("answer.good")}
                                onClick={() => {
                                    showLikePopup();
                                    onClickLikeDislike();
                                }}
                                disabled={isLikeDisabled}
                            />
                        )}
                        {isDisLikeVisible && (
                            <IconButton
                                id={`DisLikeIcon${primaryId}`}
                                style={{ color: "black" }}
                                iconProps={{ iconName: disLikeIconName }}
                                title={i18next.t("answer.bad")}
                                ariaLabel={i18next.t("answer.bad")}
                                onClick={() => {
                                    showDisLikePopup();
                                    onClickLikeDislike();
                                }}
                                disabled={isDisLikeDisabled}
                            />
                        )}
                    </Stack>
                </Stack.Item>
                {isSharChatPopupVisible && (
                    <Layer>
                        <Popup className={popupStyles.root} role="dialog" aria-modal="true" onDismiss={hideShareChatPopup}>
                            <FocusTrapZone>
                                <div role="document" className={popupStyles.content} style={{ width: "500px" }}>
                                    <div style={{ padding: "5px", fontSize: "15px", fontWeight: "bold" }}>
                                        <Icon iconName="share" style={{ padding: "5px", fontSize: "20px" }} />
                                        <span style={{ fontSize: "19px" }}>{i18next.t("answer.shareChat")}</span>
                                        <IconButton
                                            style={{ color: "black", float: "right" }}
                                            iconProps={{ iconName: "Cancel" }}
                                            onClick={hideShareChatPopup}
                                        />
                                    </div>
                                    <div className={styles.thumbTextBox} style={{ display: "grid", justifyContent: "center", width: "465px" }}>
                                        <label style={{ fontSize: "20px" }}>{i18next.t("answer.shareChatText")}</label>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                        <DefaultButton
                                            autoFocus
                                            iconProps={{ iconName: "link" }}
                                            style={{ color: "blue", borderRadius: "5px", padding: "5px", fontSize: "13px" }}
                                            onClick={() => {
                                                const queryParams = parseQueryParams(location.search);
                                                const sessionValue = queryParams.SessionId;
                                                copyToClipboard(window.location.href);
                                                hideShareChatPopup();
                                                notify();
                                                saveSharedChatValue(sessionValue);
                                            }}
                                            text={i18next.t("answer.copyLink")}
                                        ></DefaultButton>{" "}
                                    </div>
                                </div>
                            </FocusTrapZone>
                        </Popup>
                    </Layer>
                )}
                <Toaster toasterId={toasterId} position="bottom-start" pauseOnHover pauseOnWindowBlur timeout={2000} />
                {isLikePopupVisible && (
                    <Layer>
                        <Popup className={popupStyles.root} role="dialog" aria-modal="true" onDismiss={handleLikeShowHide}>
                            <FocusTrapZone>
                                <div role="document" className={popupStyles.content}>
                                    <div>
                                        <IconButton style={{ color: "green" }} iconProps={{ iconName: "LikeSolid" }} />
                                        <span style={{ padding: "5px", fontSize: "15px", fontWeight: "bold" }}>{i18next.t("answer.additionalFeedback")}</span>
                                        <IconButton
                                            style={{ color: "black", float: "right" }}
                                            iconProps={{ iconName: "Cancel" }}
                                            onClick={handleLikeShowHide}
                                        />
                                    </div>
                                    <Separator></Separator>
                                    <div>
                                        <TextField
                                            autoFocus
                                            className={styles.thumbTextBox}
                                            multiline
                                            resizable={false}
                                            placeholder={i18next.t("answer.goodFeedbackText")}
                                            onChange={(e: any) => {
                                                setTextFieldLikeValue(e.target.value);
                                            }}
                                            value={TextFieldLikeValue}
                                        />
                                    </div>

                                    <DefaultButton
                                        style={{ borderRadius: "5px", marginLeft: "40%", padding: "5px" }}
                                        onClick={() => {
                                            hideLikePopup();
                                            hideDisLike();
                                            setLikeIconName("LikeSolid");
                                            setIsLikeDisabled(true);

                                            const newAnswer = { ...answer };
                                            if (newAnswer.choices[0].context.feedbackgiven !== undefined) {
                                                newAnswer.choices[0].context.feedbackgiven = true;
                                                newAnswer.choices[0].context.feedbacktype = "Positive";
                                            }
                                            if (newAnswer.choices[0].context.feedback?.category !== undefined) {
                                                newAnswer.choices[0].context.feedback.category = 1;
                                                newAnswer.choices[0].context.feedbacktype = "Positive";
                                            }
                                            updateStreamedAnswer(primaryId, newAnswer);
                                            onFeedbackSubmitClicked({
                                                text: TextFieldLikeValue,
                                                type: "up-vote",
                                                options: 0
                                            });
                                        }}
                                    >
                                        {i18next.t("answer.submitButton")}
                                    </DefaultButton>
                                </div>
                            </FocusTrapZone>
                        </Popup>
                    </Layer>
                )}

                {isDisLikePopupVisible && (
                    <Layer>
                        <Popup className={popupStyles.root} role="dialog" aria-modal="true" onDismiss={handleDisLikeShowHide}>
                            <FocusTrapZone>
                                <div role="document" className={popupStyles.content}>
                                    <div>
                                        <IconButton style={{ color: "red" }} iconProps={{ iconName: "DisLikeSolid" }} />
                                        <span style={{ padding: "5px", fontSize: "15px", fontWeight: "bold" }}>{i18next.t("answer.additionalFeedback")}</span>
                                        <IconButton
                                            style={{ color: "black", float: "right" }}
                                            iconProps={{ iconName: "Cancel" }}
                                            onClick={handleDisLikeShowHide}
                                        />
                                    </div>
                                    <Separator></Separator>
                                    <div>
                                        <TextField
                                            autoFocus
                                            className={styles.thumbTextBox}
                                            multiline
                                            resizable={false}
                                            placeholder={i18next.t("answer.badFeedbackText")}
                                            onChange={onChangeTextFieldValue}
                                            value={TextFieldDisLikeValue}
                                        />
                                        <Stack tokens={stackTokens}>
                                            <Checkbox
                                                label={i18next.t("answer.notTrue")}
                                                onChange={_onChangeCheckBoxone}
                                                checked={isCheckBoxOneChecked}
                                                disabled={fieldDownVoteValue != 1 && fieldDownVoteValue != 0}
                                            />
                                            <Checkbox
                                                label={i18next.t("answer.notHelpful")}
                                                onChange={_onChangeCheckBoxtwo}
                                                checked={isCheckBoxTwoChecked}
                                                disabled={fieldDownVoteValue != 2 && fieldDownVoteValue != 0}
                                            />
                                            <Checkbox
                                                label={i18next.t("answer.harmful")}
                                                onChange={_onChangeCheckBoxthree}
                                                checked={isCheckBoxThreeChecked}
                                                disabled={fieldDownVoteValue != 3 && fieldDownVoteValue != 0}
                                            />
                                        </Stack>
                                    </div>

                                    <DefaultButton
                                        style={{ borderRadius: "5px", marginLeft: "40%", padding: "5px" }}
                                        onClick={() => {
                                            hideDisLikePopup();
                                            hideLike();
                                            setDisLikeIconName("DislikeSolid");
                                            setIsDisLikeDisabled(true);
                                            const newAnswer = { ...answer };
                                            if (newAnswer.choices[0].context.feedbackgiven !== undefined) {
                                                newAnswer.choices[0].context.feedbackgiven = true;
                                                newAnswer.choices[0].context.feedbacktype = "Negative";
                                            }
                                            if (newAnswer.choices[0].context.feedback?.category !== undefined) {
                                                newAnswer.choices[0].context.feedback.category = 1;
                                                newAnswer.choices[0].context.feedbacktype = "Negative";
                                            }
                                            updateStreamedAnswer(primaryId, newAnswer);
                                            onFeedbackSubmitClicked({
                                                text: TextFieldDisLikeValue,
                                                type: "down-vote",
                                                options: fieldDownVoteValue
                                            });
                                            displayLink ? displayLink() : null;
                                        }}
                                        disabled={fnDisableSubmitButton()}
                                    >
                                        {i18next.t("answer.submitButton")}
                                    </DefaultButton>
                                </div>
                            </FocusTrapZone>
                        </Popup>
                    </Layer>
                )}
            </Stack>
            {isLastAnswer && <Text className={styles.lastInfo}>{i18next.t("chat.responseInfo")}</Text>}
        </div>
    );
};
