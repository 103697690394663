import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { appInfo, userInfo } from ".././authConfig";

// the translations
const resources = {
    en: {
        common: appInfo.commonEn
    },
    ja: {
        common: appInfo.commonJa
    }
};

i18n.use(initReactI18next).init({
    resources,
    lng: userInfo.locale || "ja",
    fallbackLng: "ja",

    interpolation: {
        escapeValue: false // react already safes from xss
    },
    defaultNS: "common"
});

export default i18n;
