import { Pivot, PivotItem } from "@fluentui/react";
import DOMPurify from "dompurify";
import i18next from "../../i18n/i18n";
import { parseAnswerToHtml } from "../Answer/AnswerParser";
import { SupportingContent } from "../SupportingContent";
import { ChatAppResponse } from "../../api";
import { AnalysisPanelTabs } from "./AnalysisPanelTabs";

interface Props {
    className: string;
    activeTab: AnalysisPanelTabs | undefined;
    onActiveTabChanged: (tab: AnalysisPanelTabs) => void;
    activeCitation: string | undefined;
    citationHeight: string;
    answer: ChatAppResponse;
    index: number;
    isAdminUser?: boolean;
    onActiveContentChanged: (citationIndex: number) => void;
    isStreaming: boolean;
    sourcePage: string;
}

const pivotItemDisabledStyle = { disabled: true, style: { color: "grey" } };

export const AnalysisPanel = ({
    answer,
    activeTab,
    activeCitation,
    citationHeight,
    className,
    onActiveTabChanged,
    index,
    isAdminUser,
    onActiveContentChanged,
    isStreaming,
    sourcePage
}: Props) => {
    const isDisabledThoughtProcessTab: boolean = !answer?.choices[0]?.context?.thoughts;
    const isDisabledSupportingContentTab: boolean = !answer?.choices[0]?.context?.data_points?.length;
    const isDisabledCitationTab: boolean = !activeCitation;
    const appType = answer?.choices[0]?.context?.app_type;
    const sanitizedThoughts = DOMPurify.sanitize(answer?.choices[0]?.context?.thoughts!);
    const messageContent = typeof answer.choices[0]?.message?.content === "string" ? answer.choices[0]?.message?.content : "";
    const dataPoints = answer?.choices[0]?.context?.data_points;
    const fileMapping = answer?.choices[0]?.context?.file_mapping;
    const parsedAnswer: any = parseAnswerToHtml(messageContent, isStreaming, () => {}, dataPoints, fileMapping);
    if (parsedAnswer?.citations?.length === 0 && activeTab === AnalysisPanelTabs.SupportingContentTab) {
        return;
    }

    const citationIndexMap: any = new Map(parsedAnswer?.citations?.map((item: any, index: number) => [item, index]));
    const filteredAndSorted: any = dataPoints
        ?.filter((item: any) => citationIndexMap.has(`${item.uploaddir_field}/${item.sourcepage_field}`) || citationIndexMap.has(`${item.sourcepage_field}`))
        .sort((a: any, b: any) => {
            const aKey = `${a.uploaddir_field}/${a.sourcepage_field}`;
            const bKey = `${b.uploaddir_field}/${b.sourcepage_field}`;
            return citationIndexMap.get(aKey) - citationIndexMap.get(bKey);
        });

    return (
        <Pivot
            className={className}
            selectedKey={activeTab}
            onLinkClick={pivotItem => pivotItem && onActiveTabChanged(pivotItem.props.itemKey! as AnalysisPanelTabs)}
        >
            {isAdminUser && activeTab === AnalysisPanelTabs.ThoughtProcessTab && (
                <PivotItem
                    itemKey={AnalysisPanelTabs.ThoughtProcessTab}
                    headerText={i18next.t("analysis.thoughtProcess")}
                    headerButtonProps={isDisabledThoughtProcessTab ? pivotItemDisabledStyle : undefined}
                >
                    <SupportingContent
                        index={index}
                        supportingContent={filteredAndSorted}
                        onActiveContentChanged={onActiveContentChanged}
                        appType={appType}
                        sourcePage={sourcePage}
                        openView={AnalysisPanelTabs.ThoughtProcessTab}
                        sanitizedThoughts={sanitizedThoughts}
                    />
                </PivotItem>
            )}
            {parsedAnswer?.citations?.length > 0 && (
                <PivotItem
                    itemKey={AnalysisPanelTabs.SupportingContentTab}
                    headerText={i18next.t("analysis.supportingContent")}
                    headerButtonProps={isDisabledSupportingContentTab ? pivotItemDisabledStyle : undefined}
                >
                    <SupportingContent
                        index={index}
                        supportingContent={filteredAndSorted}
                        onActiveContentChanged={onActiveContentChanged}
                        appType={appType}
                        sourcePage={sourcePage}
                        openView={AnalysisPanelTabs.SupportingContentTab}
                    />
                </PivotItem>
            )}
            {activeTab === AnalysisPanelTabs.CitationTab && (
                <PivotItem
                    itemKey={AnalysisPanelTabs.CitationTab}
                    headerText={i18next.t("analysis.citation")}
                    headerButtonProps={isDisabledCitationTab ? pivotItemDisabledStyle : undefined}
                >
                    <iframe title={i18next.t("analysis.citation")} src={activeCitation} width="100%" height={citationHeight} />
                </PivotItem>
            )}
        </Pivot>
    );
};
